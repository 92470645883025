import request from '@/utils/requestV2'
const qs = require('qs')

// 绩效类型：获取绩效类型详情
export function getCommissionType (data) {
  return request({
    url: '/ooh-scp/v1/companycommissionsetting/commissiontype/getcommissiontype',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 绩效类型：获取所有绩效类型
export function getCommissionTypePage (data) {
  return request({
    url: '/ooh-scp/v1/companycommissionsetting/commissiontype/getcommissiontypepage',
    method: 'post',
    data: qs.stringify(data)
  })
}
