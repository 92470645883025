<template>
    <div style="margin:10px">
      <row class="p-b-10">
        绩效类别：
        <Dropdown v-if="stepType === 1" size="small"  class="m-l-20" @on-click="changeType">
            <Button type="primary">
                {{commissionName}}<Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
               <DropdownItem :name="item.id" v-for="item in commissionTypes" :key="item.id">{{item.typeName}}</DropdownItem>
            </DropdownMenu>
        </Dropdown>
        <Tag v-else border color="blue"> {{setting.name}}</Tag>
      </row>
      <row class="p-b-10"  >
        佣金明细：（<span class="text-green">此数据仅供参考，且只计算发布费佣金，服务费以实际发生为准</span>）
        <Table stripe  :columns="columns" :data="commissions"></Table>
      </row>
      <row class="p-b-10">
        <i-col span="24">
          <span class="p-b-10">佣金发放时间：</span><br/>
          <commissionlist :assignItems="commissionsToAssign" ></commissionlist>
        </i-col>
      </row>
      <row class="p-b-10">
        <i-col span="12">
          <Button type="info" @click="handlePreviousStep">上一步</Button>
        </i-col>
        <i-col span="12" class="text-right">
          <Button type="info" :loading="loadingNext" @click="handleNextStep">下一步</Button>
        </i-col>
      </row>
    </div>
</template>

<script>
import commissionlist from '@/components/contract/contractDetail/detail/Commissionlist'

import { getCommissionType, getCommissionTypePage } from '@/api/scp/companycommissionsetting'
import { GetDateStr } from '@/utils/dateFormat'
import { getLaterDate } from '@/utils/index'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    params: {
      type: Object,
      required: true
    },
    stepType: {
      type: Number,
      default: 1
    }
  },
  components: {
    commissionlist
  },
  data () {
    return {
      loadingNext: false,
      commissionTypes: [],
      currentId: -11,
      columns: [
        { title: '产品名称', key: 'productName' },
        {
          title: '发布费结算价',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.totalSettlementPrice))
          }
        },
        {
          title: '最终执行价',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.sellPrice))
          }
        },
        {
          title: '服务费',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.serviceFee))
          }
        },
        { title: '绩效计算方式', key: 'desc', tooltip: true },
        {
          title: '发布费绩效',
          render: (h, params) => {
            return h('span', { style: { color: '#19be6b' } }, toMoney(params.row.commission))
          }
        },
        {
          title: '发布费提成',
          render: (h, params) => {
            return h('span', { style: { color: '#19be6b' } }, toMoney(params.row.kpi))
          }
        },
        {
          title: '服务费提成',
          render: (h, params) => {
            return h('span', { style: { color: '#19be6b' } }, toMoney(params.row.serviceKpi))
          }
        },
        {
          title: '合计佣金',
          render: (h, params) => {
            return h('span', { style: { color: '#ed4014' } }, toMoney(params.row.commission + params.row.kpi + params.row.serviceKpi))
          }
        }
      ],
      commissionName: '',
      setting: {}
    }
  },
  methods: {
    getTypeDetail () {
      getCommissionType({ commissiontypeId: this.currentId }).then(res => {
        const normalSettings = res.normalsetting.productGroupCommissionAlgorithm.commissionAlgorithmList
        const fixed = normalSettings.find(x => x.type === 2)
        const basic = normalSettings.find(x => x.type === 1)
        const normalBasic = Object.assign({}, basic.servicefee, basic.usefee)
        const normalFixed = { serviceFixed: fixed.servicefee.fixedPoint, sellFixed: fixed.usefee.fixedPoint }
        const normal = Object.assign({}, normalBasic, normalFixed)
        this.setting = Object.assign({}, res, { normal: normal })
      })
    },
    changeType (type) {
      this.currentId = type
      this.commissionName = this.commissionTypes.find(x => x.id === this.currentId).typeName
      this.getTypeDetail()
    },
    handleNextStep () {
      this.loadingNext = true
      this.$emit('next-step', { contractId: this.params.id, commissiontypeId: this.currentId })
    },
    handlePreviousStep () {
      this.$emit('prev-step', this.currentId)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    cannelLoading () {
      this.loadingNext = false
    }
  },
  created () {
    if (this.stepType === 1) {
      getCommissionTypePage({ pageSize: 100, pageNumber: 1, status: 1, channelType: 0 }).then(res => {
        this.commissionTypes = res.list
        if (res.totalRow > 0) {
          this.currentId = this.commissionTypes[0].id
          this.commissionName = this.commissionTypes[0].typeName
          this.getTypeDetail()
        }
      })
    } else {
      this.currentId = this.params.commissiontypeId
      this.getTypeDetail()
    }
  },
  computed: {
    products: function () {
      const productMap = new Map()
      const priceOrder = this.params.priceOrder
      priceOrder.forEach(order => {
        order.products.forEach(product => {
          let isSpecial = false
          if (this.setting.specialgroupsettingList) {
            isSpecial = this.setting.specialgroupsettingList.some(x => x.productList.map(p => p.productId).includes(product.productId))
          }
          const productPrice = productMap.get(product.productId) || { totalUsePrice: 0, totalSettlementPrice: 0, sellPrice: 0, serviceFee: 0 }
          productPrice.totalUsePrice += product.recommendUsePrice || 0
          productPrice.totalUsePrice += product.normalUsePrice || 0
          productPrice.totalUsePrice += product.usePrice || 0

          productPrice.totalSettlementPrice += product.normalSettlementPrice || 0
          productPrice.totalSettlementPrice += product.recommendSettlementPrice || 0
          productPrice.totalSettlementPrice += product.settlementPrice || 0

          productPrice.sellPrice += product.recommendSellPrice || 0
          productPrice.sellPrice += product.normalSellPrice || 0

          productPrice.serviceFee += product.installPrice || 0
          productPrice.serviceFee += product.monitorPrice || 0
          productPrice.serviceFee += product.producePrice || 0
          productMap.set(product.productId, Object.assign({}, product, productPrice, { isSpecial: isSpecial }))
        })
      })
      return [...productMap.values()]
    },
    commissions: function () {
      const commissions = []
      this.products.forEach(product => {
        const commission = {
          desc: '',
          kpi: 0,
          commission: 0,
          method: 0,
          fixedPoint: 0,
          basic: 0,
          coefficient: 0,
          serviceKpi: 0,
          serviceFixed: 0,
          sellFixed: 0
        }

        if (product.isSpecial) {
          commission.method = 1 // 特殊产品
          const specialgroupsettingList = this.setting.specialgroupsettingList || []
          const specialSetting = specialgroupsettingList.find(x => x.productList.some(p => p.productId === product.productId)).productGroupCommissionAlgorithm
          commission.desc = '发布费执行价 * ' + specialSetting.usefee.fixedPoint + '+ 服务费 * ' + specialSetting.servicefee.fixedPoint
          commission.serviceFixed = specialSetting.servicefee.fixedPoint
          commission.sellfixed = specialSetting.usefee.fixedPoint
          commission.serviceKpi = product.serviceFee * specialSetting.servicefee.fixedPoint
          commission.commission = product.sellPrice * specialSetting.usefee.fixedPoint
        } else {
          const normalsetting = this.setting.normal || { fixedPoint: 0, basic: 0, coefficient: 0, sellFixed: 0, serviceFixed: 0 }
          const sellfixed = product.sellPrice * normalsetting.sellFixed
          const serviceFixed = product.serviceFee * normalsetting.serviceFixed
          const comm = (product.sellPrice - product.totalSettlementPrice) * normalsetting.coefficient
          const kpi = product.totalSettlementPrice * normalsetting.basic
          const serviceKpi = product.serviceFee * normalsetting.fixedPoint

          commission.coefficient = normalsetting.coefficient
          commission.basic = normalsetting.basic
          commission.fixedPoint = normalsetting.fixedPoint
          commission.serviceFixed = normalsetting.serviceFixed
          commission.sellFixed = normalsetting.sellFixed
          if (sellfixed + serviceFixed > (comm + kpi + serviceKpi)) {
            commission.method = 2 // 固定返点
            commission.desc = '发布费执行价 * ' + normalsetting.sellFixed + '+ 服务费 *' + normalsetting.serviceFixed
            commission.commission = sellfixed
            commission.serviceKpi = serviceFixed
          } else {
            commission.method = 3 // 系数
            commission.desc = '(发布费执行价 - 发布费结算价) * ' + normalsetting.coefficient + '+ 发布费结算价 *' + normalsetting.basic + '+ 服务费 *' + normalsetting.fixedPoint
            commission.commission = comm
            commission.kpi = kpi
            commission.serviceKpi = serviceKpi
          }
        }
        commissions.push(Object.assign({}, product, commission))
      })
      return commissions
    },
    commissionsToAssign: function () {
      let items = []
      const method = this.setting.commissionSettlementForm
      const scheduleLastDate = getLaterDate(this.params.schedulePrice.map(x => x.endDate))
      const paymentLastDate = getLaterDate(this.params.payments.map(x => x.date))
      const lastDate = getLaterDate([scheduleLastDate, paymentLastDate])

      switch (method) {
        case 10: // 按档期，以档期结束时间计算截至日期
          items = this.scheduleCommission.map(x => {
            return {
              date: x.deadLine,
              money: x.money,
              commission: x.commission
            }
          })
          break
        case 30: // 合同执行和回款
          items = [
            { date: lastDate, money: this.total.money, commission: this.total.commission }
          ]
          break
        case 40: // 按回款计
          items = [
            {
              date: getLaterDate(this.params.payments.map(x => x.date)),
              money: this.total.money,
              commission: this.total.commission
            }
          ]
          break
        default:
          break
      }

      return items
    },
    scheduleCommission: function () {
      const scheduleMap = new Map()
      this.params.priceOrder.forEach(order => {
        order.products.forEach(product => {
          const delay = this.setting.postponeDay || 0
          const deadLine = GetDateStr(product.endDate, delay)
          const schedulePrice = scheduleMap.get(deadLine) || { deadLine: deadLine, money: 0, commission: 0 }
          const productCommission = this.commissions.find(x => x.productId === product.productId)
          const sellPrice = product.recommendSellPrice + product.normalSellPrice
          const serviceFee = product.monitorPrice + product.producePrice + product.installPrice
          const settlementPrice = product.normalSettlementPrice || 0 + product.recommendSettlementPrice || 0 + product.settlementPrice || 0
          schedulePrice.money += sellPrice
          schedulePrice.money += serviceFee
          switch (productCommission.method) {
            case 1:
              schedulePrice.commission += sellPrice * productCommission.sellFixed
              schedulePrice.commission += serviceFee * productCommission.serviceFixed
              break
            case 2:
              schedulePrice.commission += sellPrice * productCommission.sellFixed
              schedulePrice.commission += serviceFee * productCommission.serviceFixed
              break
            case 3:
              schedulePrice.commission += (sellPrice - settlementPrice) * productCommission.coefficient
              schedulePrice.commission += settlementPrice * productCommission.basic
              schedulePrice.commission += serviceFee * productCommission.fixedPoint
              break
            default:
              break
          }
          scheduleMap.set(deadLine, schedulePrice)
        })
      })
      return [...scheduleMap.values()]
    },
    total: function () {
      const total = {
        money: 0,
        commission: 0
      }
      this.scheduleCommission.forEach(commission => {
        total.money += commission.money
        total.commission += commission.commission
      })
      return total
    }
  }

}
</script>
